import MobileDetect from 'mobile-detect';
import { districts } from '../data/districts.json';

export default {
    init(el) {
        // const appendLabel = el.querySelector('.js-append');
        const district = this.getDistrict();
        const urlParams = this.getUrlParams();

        if (district) {
            // const template = appendLabel.getAttribute('data-append-' + district.slug) || appendLabel.getAttribute('data-append');

            // appendLabel.textContent = appendLabel.textContent + template + district.name;
            el.querySelector('[data-location]').value = district.name;
        }

        if (urlParams) {
            Object.keys(urlParams).forEach(key => {
                const item = el.querySelector('[data-' + key + ']');
                item && (item.value = urlParams[key]);
            });
        }

        this.setDeviceCategory(el);
    },

    getDistrict() {
        return districts.find(item => {
            const regex = new RegExp(item.slug + '\\b', 'g');

            return window.location.pathname.match(regex);
        });
    },

    getUrlParams() {
        return !window.location.search
            ? null
            : window.location.search
                  .substring(1)
                  .split('&')
                  .reduce(function(acc, cur) {
                      const pair = cur.split('=');
                      const key = pair[0].replace('utm_', '');
                      acc[key] = pair[1];
                      return acc;
                  }, {});
    },

    setDeviceCategory(el) {
        const md = new MobileDetect(window.navigator.userAgent);
        const isMobile = Boolean(md.mobile());
        let deviceCategory = 'desktop';

        if (isMobile) {
            if (md.phone()) {
                deviceCategory = 'mobile';
            } else if (md.tablet()) {
                deviceCategory = 'tablet';
            }
        }
        if (el.querySelector('[data-device_category]')) {
            el.querySelector('[data-device_category]').value = deviceCategory;
        }
    }
};
