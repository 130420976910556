export default {
    init(el) {
        el.querySelector('.js-button').addEventListener('click', e => this.toggleSection(e));
    },

    toggleSection(e) {
        const button = e.target;
        const section = document.getElementById(button.getAttribute('aria-controls'));
        const open = button.getAttribute('aria-expanded') === 'true' ? true : false;

        button.setAttribute('aria-expanded', !open);
        section.setAttribute('aria-hidden', open);
    },
};
