class WallopHelper {
    constructor(slider, el) {
        this.slider = slider;

        el.addEventListener('touchstart', e => this.touchStart(e));
        el.addEventListener('touchmove', e => this.touchHandler(e));
    }

    keyboardNav(e) {
        switch (e.keyCode) {
            case 37:
                this.slider.previous();
                break;
            case 39:
                this.slider.next();
                break;
        }
    }

    pointerPos(e) {
        return e.pageX || e.touches[0].pageX || e.changedTouches[0].pageX;
    }

    touchHandler(e) {
        if (this.canMove) {
            const distance = Math.ceil(this.pointerPos(e)) - Math.ceil(this.initPos);

            if (distance < -10) {
                this.slider.next();
            }

            if (distance > 10) {
                this.slider.previous();
            }

            this.canMove = false;
        }
    }

    touchStart(e) {
        this.initPos = this.pointerPos(e);
        this.canMove = true;
    }
}

export default WallopHelper;
