import 'normalize.css/normalize.css';
import './styles/index.scss';

import hero from './controls/hero';
import form from './controls/form';
import slider from './controls/slider';
import collapsible from './controls/collapsible';

const controls = {
    hero,
    form,
    slider,
    collapsible
};

document.addEventListener('DOMContentLoaded', () => {
    [...document.querySelectorAll('[data-control]')].forEach(module =>
        controls[module.getAttribute('data-control')]['init'](module)
    );
});
