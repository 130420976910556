import Wallop from 'wallop';
import WallopHelper from './helpers/wallop-helper';

export default {
    init(el) {
        const wallop = new Wallop(el, {
            itemClass: 'js-slide',
            currentItemClass: 'current',
        });

        new WallopHelper(wallop, el);

        el.querySelector('.js-next').addEventListener('click', () => wallop.next());
        el.querySelector('.js-prev').addEventListener('click', () => wallop.previous());
    },
};
